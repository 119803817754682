<template>
	<el-button @click="handleDownload">下载</el-button>
</template>

<script>
import { Button, Message } from "element-ui";
export default {
	name: "OralSanningModelView",
	components: {
		ElButton: Button,
	},
	props: {
		no: {
			type: String,
			required: true,
		},
		type: {
			validator: function (value) {
				return ['maxillary', 'mandible'].indexOf(value) !== -1;
			},
		},
	},
	data() {
		return {
			currentUrl: "",
			currentUrlExpireTime: 0,
		};
	},
	methods: {
		async fetch(no) {
			return await this.$api.manager.order.getDocumentDownloadUrl(
				no,
				"OralSanningModel" + this.type.charAt(0).toUpperCase() + this.type.slice(1)
			);
		},
		hasAvailableUrl() {
			return this.currentUrlExpireTime > Date.now() + 1000;
		},
		async handleDownload() {
			if (this.hasAvailableUrl()) {
				window.open(this.currentUrl);
			} else {
				let res;
				try {
					let { data } = await this.fetch(this.no);
					console.log(data);
					res = data;
				} catch (error) {
					Message.error("获取下载链接失败: " + error);
					console.error(error);
					return;
				}

				if (res.code === "OK") {
					if (this.currentUrl == "") {
						this.currentUrl = res.data.url;
						this.currentUrlExpireTime = res.data.expireTime;
					}

					window.open(res.data.url);
				} else {
					Message.error("获取下载链接失败: " + res.errmsg);
				}
			}
		},
	},
	watch: {
		async no() {
			this.currentUrl = "";
			this.currentUrlExpireTime = 0;
		},
		async type() {
			this.currentUrl = "";
			this.currentUrlExpireTime = 0;
		},
	},
};
</script>

<style>
</style>