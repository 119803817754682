<template>
	<div class="checking-order-view" ref="self">
		<div v-if="isError" style="text-align: center">
			<i class="el-icon-error" style="color: #f56c6c; font-size: 50px"></i>
			<p>加载失败！</p>
			<el-button @click="load(no)">重试</el-button>
		</div>
		<div v-show="!isError">
			<h3>订单信息</h3>
			<el-table :data="[orderData]" row-class-name="data-row" border>
				<el-table-column property="orderNo" label="订单号"></el-table-column>

				<el-table-column label="创建日期">
					<template slot-scope="scope">
						<i class="el-icon-date"></i>
						<span style="margin-left: 10px">{{
							$day.unix(scope.row.createdAt).format("YYYY-MM-DD HH:mm")
						}}</span>

						<span style="margin-left: 20px"></span>
						<i class="el-icon-time"></i>
						<span style="margin-left: 10px">{{
							$day.unix(scope.row.createdAt).fromNow()
						}}</span>
					</template>
				</el-table-column>
			</el-table>

			<h3>医生资料</h3>
			<el-table :data="[orderData]" row-class-name="data-row" border>
				<el-table-column
					property="doctor_fullname"
					label="姓名"
					width="150"
				></el-table-column>
				<el-table-column
					property="doctor_phoneNumber"
					label="手机号"
					width="200"
				></el-table-column>
				<el-table-column
					property="doctor_address"
					label="地址"
				>
				
				<template slot-scope="scope">
					{{
						spellAdminText(
							scope.row.doctor_province,
							scope.row.doctor_city,
							scope.row.doctor_area
						)
					}}
					{{ scope.row.doctor_address }}
				</template>
				</el-table-column>
				<el-table-column property="doctor_email" label="邮箱"></el-table-column>
			</el-table>

			<h3>病人</h3>
			<el-table :data="[orderData]" row-class-name="data-row" border>
				<el-table-column
					property="patient_fullname"
					label="姓名"
					width="150"
				></el-table-column>
				<el-table-column label="性别">
					<template slot-scope="scope">
						{{ scope.row.patient_gender ? "女" : "男" }}
					</template>
				</el-table-column>
				<el-table-column label="出生日期">
					<template slot-scope="scope">
						{{ $day(scope.row.patient_birthday).format("YYYY-MM-DD") }}
						({{
							$day().year() - $day(scope.row.patient_birthday).year() + 1
						}}岁)
					</template>
				</el-table-column>
			</el-table>

			<h3>诊断设计</h3>
			<el-card shadow="never">{{ orderData.design.replace(/\n/g,"&lt;br/&gt;") }} </el-card>
			<h3>拔牙牙位</h3>
			<el-card shadow="never">{{ orderData.removeTeeth.replace(/\n/g,"&lt;br/&gt;") }} </el-card>
			<h3>修复牙位</h3>
			<el-card shadow="never">{{ orderData.repairTeeth.replace(/\n/g,"&lt;br/&gt;") }} </el-card>
			
			<h3>头影侧位片</h3>
			<lateral-cephalo-radiography-image-view :no="no"/>
			<h3>病例文档</h3>
			<case-docment-view :no="no"/>
			<h3>口扫模型-上</h3>
			<oral-sanning-model-view :no="no" type="maxillary"/>
			<h3>口扫模型-下</h3>
			<oral-sanning-model-view :no="no" type="mandible"/>
		</div>
	</div>
</template>

<script>
import { Table, TableColumn, Card, Loading, Button } from "element-ui";
import LateralCephaloRadiographyImageView from './LateralCephaloRadiographyImageView.vue';
import OralSanningModelView from './OralSanningModelView.vue';
import { spellAdminText } from "../api/utils";
import CaseDocmentView from './CaseDocmentView.vue';
export default {
	name: "CheckingOrderView",
	components: {
		ElTable: Table,
		ElTableColumn: TableColumn,
		ElCard: Card,
		ElButton: Button,
		LateralCephaloRadiographyImageView,
		OralSanningModelView,
		CaseDocmentView,
	},
	props: {
		no: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			isError: false,
			orderData: {
				no: "6807937734360043520",
				state: 0,
				payState: 0,
				createdAt: 1623138841,
				updatedAt: 1623138841,
				orderNo: "6807937734360043520",
				doctor_fullname: "陈医生",
				doctor_province: 35,
				doctor_city: 9,
				doctor_area: 2,
				doctor_address: "中富大厦903",
				doctor_phoneNumber: "17665315655",
				doctor_email: "",
				patient_fullname: "黄病人",
				patient_gender: 0,
				patient_birthday: "2021-06-28T16:00:00.000Z",
				design: "设计设计设计设计设计设计设计设计设计",
				removeTeeth: "拔牙牙位拔牙牙位拔牙牙位拔牙牙位拔牙牙位拔牙牙位拔牙牙位",
				repairTeeth: "修复牙位修复牙位修复牙位修复牙位修复牙位",
			},
		};
	},
	async mounted() {
		await this.load(this.no);
	},
	methods: {
		async load(no) {
			let loadingInstance = Loading.service({
				target: this.$refs.self,
				fullscreen: false,
			});

			let res;
			try {
				let { data } = await this.$api.manager.order.getForChecking(no);
				res = data;
			} catch (error) {
				console.error(error);
				this.isError = true;
				loadingInstance.close();
				return;
			}

			if (res.code === "OK") {
				this.$set(this, "orderData", res.data);
				this.isError = false;
			} else {
				this.isError = true;
			}

			loadingInstance.close();
		},
		spellAdminText,
	},
	watch: {
		async no(newNo) {
			await this.load(newNo);
		},
	},
};
</script>

<style>
.checking-order-view .data-row {
	background-color: #f5f7fa;
}
</style>