<template>
	<div ref="self">
		<div v-if="isError" style="text-align: center">
			<i class="el-icon-error" style="color: #f56c6c; font-size: 50px"></i>
			<p>加载失败！</p>
			<p>({{ error }})</p>
			<el-button @click="getLiset">重试</el-button>
		</div>

		<div v-show="!isError" style="overflow: hidden; margin-bottom: 20px">
			<el-radio-group v-model="filter" size="small" style="float: left">
				<el-radio-button label="all">全部</el-radio-button>
				<el-radio-button label="waiting">待审</el-radio-button>
				<el-radio-button label="passed">已通过</el-radio-button>
				<el-radio-button label="failed">未通过</el-radio-button>
			</el-radio-group>
			<el-button
				icon="el-icon-refresh-right"
				size="small"
				style="float: right"
				@click="getLiset"
			></el-button>
		</div>
		<el-table v-show="!isError" :data="tableData" stripe style="width: 100%">
			<el-table-column label="# 订单号" type="index" width="200">
				<template slot-scope="scope">
					{{ scope.row.orderNo }}
				</template>
			</el-table-column>

			<el-table-column label="病人" width="180">
				<template slot-scope="scope">
					<el-popover trigger="hover" placement="bottom">
						<p>性别: {{ scope.row.patient_gender ? "女" : "男" }}</p>
						<p>
							出生日期:
							{{ $day(scope.row.patient_birthday).format("YYYY-MM-DD") }} ({{
								$day().year() - $day(scope.row.patient_birthday).year() + 1
							}}岁)
						</p>
						<div slot="reference">
							<el-tag size="medium">{{ scope.row.patient_fullname }}</el-tag>
						</div>
					</el-popover>
				</template>
			</el-table-column>

			<el-table-column label="医生" width="180">
				<template slot-scope="scope">
					<el-popover trigger="hover" placement="bottom">
						<p>
							地址:
							{{
								spellAdminText(
									scope.row.doctor_province,
									scope.row.doctor_city,
									scope.row.doctor_area
								)
							}}
							{{ scope.row.doctor_address }}
						</p>
						<p>邮箱: {{ scope.row.doctor_email }}</p>
						<div slot="reference">
							<el-tag size="medium" type="success">{{
								scope.row.doctor_fullname
							}}</el-tag>
						</div>
					</el-popover>
				</template>
			</el-table-column>

			<el-table-column label="手机号" width="180">
				<template slot-scope="scope">
					{{ scope.row.doctor_phoneNumber }}
				</template>
			</el-table-column>

			<el-table-column label="创建日期" width="300">
				<template slot-scope="scope">
					<i class="el-icon-date"></i>
					<span style="margin-left: 10px">{{
						$day.unix(scope.row.createdAt).format("YYYY-MM-DD HH:mm")
					}}</span>

					<span style="margin-left: 20px"></span>
					<i class="el-icon-time"></i>
					<span style="margin-left: 10px">{{
						$day.unix(scope.row.createdAt).fromNow()
					}}</span>
				</template>
			</el-table-column>
			<el-table-column label="状态" width="100">
				<template slot-scope="scope">
					<el-tag v-if="scope.row.state === 0" type="info">等待审核</el-tag>
					<el-tag v-if="scope.row.state === 2" type="success"
						>已审核通过</el-tag
					>
					<el-tag v-if="scope.row.state === 1" type="danger">审核失败</el-tag>
				</template>
			</el-table-column>
			<el-table-column align="right" label="操作">
				<template slot-scope="scope">
					<el-button
						size="mini"
						icon="el-icon-view"
						plain
						@click="handleView(scope.$index, scope.row)"
						>查看详情</el-button
					>
					<el-button
						v-if="scope.row.state === 0"
						size="mini"
						type="danger"
						icon="el-icon-close"
						plain
						@click="handleCheckFailed(scope.$index, scope.row)"
						>审核失败</el-button
					>
					<el-button
						v-if="scope.row.state === 0"
						size="mini"
						type="success"
						icon="el-icon-check"
						plain
						@click="handleCheck(scope.$index, scope.row)"
						>审核通过</el-button
					>
				</template>
			</el-table-column>
		</el-table>
		<div style="margin-top: 1em"></div>
		<el-pagination
			v-show="!isError"
			background
			layout="prev, pager, next"
			:page-count="totalPage"
			:current-page.sync="currentPage"
			@current-change="handleCurrentChange"
		></el-pagination>

		<el-dialog
			title="查看订单"
			:visible="true"
			v-if="currentViewNo > 0"
			@close="handleViewClose"
		>
			<checking-order-view :no="currentViewNo" />
		</el-dialog>

		<el-dialog
			title="订单审核失败"
			:visible="true"
			v-if="willCheckFailedNo > 0"
			@close="willCheckFailedNo = 0"
		>
			<el-form
				:model="checkFailedFormData"
				:rules="checkFailedFormRules"
				ref="checkFailedForm"
			>
				<el-form-item label="未通过原因" prop="reason">
					<el-input
						type="textarea"
						v-model="checkFailedFormData.reason"
					></el-input>
				</el-form-item>
			</el-form>

			<div slot="footer">
				<el-button @click="willCheckFailedNo = 0">取 消</el-button>
				<el-button
					type="primary"
					@click="handleCheckFailedSubmit"
					:disabled="checkFailedSubmitting"
					>确 定</el-button
				>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	Table,
	TableColumn,
	Button,
	Popover,
	Tag,
	Pagination,
	Loading,
	Dialog,
	Message,
	MessageBox,
	RadioGroup,
	RadioButton,
	Form,
	FormItem,
	Input,
} from "element-ui";

import CheckingOrderView from "../../components/CheckingOrderView.vue";
import { spellAdminText } from "../../api/utils";

export default {
	name: "CheckingConsole",
	components: {
		ElTable: Table,
		ElTableColumn: TableColumn,
		ElButton: Button,
		ElPopover: Popover,
		ElTag: Tag,
		ElPagination: Pagination,
		ElDialog: Dialog,
		ElRadioGroup: RadioGroup,
		ElRadioButton: RadioButton,
		ElForm: Form,
		ElFormItem: FormItem,
		ElInput: Input,
		CheckingOrderView,
	},
	data() {
		return {
			pageSize: 10,
			currentPage: 1,
			totalPage: 0,
			filter: "waiting",
			isError: false,
			error: "",
			currentViewNo: 0,
			willCheckFailedNo: 0,
			checkFailedSubmitting: false,
			checkFailedFormData: {
				reason: "",
			},
			checkFailedFormRules: {
				reason: [
					{ required: true, message: "请输入未通过原因", trigger: "blur" },
					{
						min: 5,
						max: 1000,
						message: "长度在 5 到 1000 个字符",
						trigger: "blur",
					},
				],
			},
			tableData: [
				// {
				// 	no: "6807937734360043520",
				// 	state: 0,
				// 	payState: 0,
				// 	createdAt: 1623138841,
				// 	updatedAt: 1623138841,
				// 	orderNo: "6807937734360043520",
				// 	doctor_fullname: "陈医生",
				// 	doctor_province: 35,
				// 	doctor_city: 9,
				// 	doctor_area: 2,
				// 	doctor_address: "中富大厦903",
				// 	doctor_phoneNumber: "17665315655",
				// 	doctor_email: "",
				// 	patient_fullname: "黄病人",
				// 	patient_gender: 0,
				// 	patient_birthday: "2021-06-28T16:00:00.000Z",
				// 	design: "设计设计设计设计设计设计设计设计设计",
				// 	removeTeeth:
				// 		"拔牙牙位拔牙牙位拔牙牙位拔牙牙位拔牙牙位拔牙牙位拔牙牙位",
				// 	repairTeeth: "修复牙位修复牙位修复牙位修复牙位修复牙位",
				// },
			],
		};
	},
	async mounted() {
		await this.getLiset();
	},
	methods: {
		async getLiset() {
			let loadingInstance = Loading.service({
				target: this.$refs.self,
				fullscreen: false,
			});
			let res;
			try {
				let { data } = await this.$api.manager.order.getChekingList(
					this.pageSize,
					this.currentPage,
					this.filter
				);
				res = data;
			} catch (e) {
				console.error(e);
				this.isError = true;
				this.error = e;
				loadingInstance.close();
				return;
			}

			if (res.code === "OK") {
				this.$set(this, "tableData", res.data.rows);
				this.totalPage = res.data.totalPage;
				this.isError = false;
				this.error = "";
			} else {
				this.isError = true;
				this.error = res.errmsg;
				if (res.code === "INVALID_SESSION") {
					this.error += ", 请重新登录";
				}
			}

			loadingInstance.close();
		},
		handleView(index, row) {
			this.currentViewNo = row.orderNo;
		},
		handleViewClose() {
			this.currentViewNo = 0;
		},
		async handleCheck(index, row) {
			const confirmed = await MessageBox.confirm(
				"你即将审过通过该订单, 请确认所有项目都已仔细确认，通过后不可撤销! 仍然审核通过？",
				"确认"
			).catch(() => {});
			if (!confirmed) return;
			try {
				const { data } = await this.$api.manager.order.checked(row.orderNo);
				if (data.code === "OK") {
					Message.success("审核操作成功");
					await this.getLiset();
				} else {
					Message.error("错误: " + data.errmsg);
				}
			} catch (e) {
				Message.error("错误: " + e);
			}
		},
		handleCheckFailed(index, row) {
			this.willCheckFailedNo = row.orderNo;
		},
		async handleCheckFailedSubmit() {
			if (this.willCheckFailedNo < 1) return;
			if(this.checkFailedSubmitting) return;
			this.checkFailedSubmitting = true;
			this.$refs.checkFailedForm.validate(async (valid) => {
				if (!valid) {
					Message.error("请输入完整正确后再提交哦！");
					this.checkFailedSubmitting = false;
					return false;
				}
				try {
					const { data } = await this.$api.manager.order.checkFailed(
						this.willCheckFailedNo,
						this.checkFailedFormData.reason
					);
					if (data.code === "OK") {
						Message.success("审核操作成功！");
						this.checkFailedFormData.reason = "";
						this.willCheckFailedNo = 0;
						await this.getLiset();
					} else {
						Message.error("错误：" + data.errmsg);
					}
				} catch (e) {
					Message.error("错误：" + e);
				}
				
				this.checkFailedSubmitting = false;
			});
		},
		async handleCurrentChange() {
			await this.getLiset();
		},
		spellAdminText,
	},
	watch: {
		async filter() {
			await this.getLiset();
		},
	},
};
</script>

<style>
</style>