<template>
	<div ref="self">
		<div v-if="isError" style="text-align: center">
			<i class="el-icon-error" style="color: #f56c6c; font-size: 50px"></i>
			<p>加载失败！</p>
			<el-button @click="load(no)">重试</el-button>
		</div>

		<div v-show="!isError">
			<el-image v-if="showImage" :src="currentUrl"></el-image>
			<el-button @click="handleShow" v-if="!showImage">显示</el-button>
			<el-button @click="handleDownload">下载</el-button>
		</div>
	</div>
</template>

<script>
import { Loading, Button, Image, Message } from "element-ui";

export default {
	name: "LateralCephaloRadiographyImageView",
	components: {
		ElButton: Button,
		ElImage: Image,
	},
	props: {
		no: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			isError: false,
			currentUrl: '',
			showImage: false,
			currentUrlExpireTime: 0,
		};
	},
	async mounted() {},
	methods: {
		async fetch(no) {
			return await this.$api.manager.order.getDocumentDownloadUrl(
				no,
				'LateralCephaloRadiographyImage'
			)
		},
		hasAvailableUrl() {
			return this.currentUrlExpireTime > Date.now() + 1000;
		},
		async handleShow() {
			await this.load(this.no);
			this.showImage = true;
		},
		async handleDownload() {
			if (this.hasAvailableUrl()) {
				window.open(this.currentUrl)
			} else {
				let res;
				try {
					let { data } = await this.fetch(this.no);
					console.log(data)
					res = data;
				} catch (error) {
					Message.error('获取下载链接失败: ' + error);
					console.error(error);
					return;
				}

				if (res.code === 'OK') {
					if (this.currentUrl == '') {
						this.currentUrl = res.data.url
						this.currentUrlExpireTime = res.data.expireTime
					}
					
					window.open(res.data.url)
				} else {
					Message.error('获取下载链接失败: ' + res.errmsg);
				}
			}
		},
		async load(no) {
			if(this.hasAvailableUrl()) return;
			let loadingInstance = Loading.service({
				target: this.$refs.self,
				fullscreen: false,
			});
			
			this.currentUrl = ''
			this.currentUrlExpireTime = 0

			let res;
			try {
				let { data } = await this.fetch(no);
				res = data;
			} catch (error) {
				console.error(error);
				this.isError = true;
				loadingInstance.close();
				return;
			}

			if (res.code === 'OK') {
				this.currentUrl = res.data.url;
				this.currentUrlExpireTime = res.data.expireTime;
				this.isError = false;
			} else {
				this.isError = true;
			}

			loadingInstance.close();
		},
	},
	watch: {
		async no(newNo) {
			this.currentUrl = ''
			this.currentUrlExpireTime = 0
			await this.load(newNo);
		},
	},
};
</script>

<style>
</style>